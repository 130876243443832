
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
import { apiEndpoint } from "@/mixin/apiEndpoint";
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'add-claim-2',
    components: {
        Datatable,
    },
    data() {
        return {
            showSection: false,
            api_url:'',
            url: '',
            bill: {
                file: '',
                claim_no: '',
                tranche_id: '',
                entity_id: '',
                entity_contract_id: '',
                bill_date: '',
                bill_sequence_no: '',
            },
            batch: {},
            tranches: [],
            entityInfos: [],
            allBillNo: [],
            billList: [],
            batchList: [],
            contractInfos: [],
            claimBillkey: 0,
            tolatTrainee: 0,
            tolatAssessedTrainee: 0,
            tolatCertifiedTrainee: 0,
            tolatBillAmount: 0,
            contributionPay: 0,
            totalContributionAmount: 0,
            totalPayableAmount: 0,
            load: false,
            loading: false,
            courseInfoData: false,
            showBillList: false,
            showtrainingProviderNotice: false,
        };
    },
    async created() {
        await this.getTranche();
        await this.getAssociation();
        this.api_url = this.VUE_APP_API_URL;
    },
    methods: {
        async getTranche() {
            this.load = true;
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    this.tranches = response.data.data;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getAssociation() {
            this.load = true;
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get('entity/list?entity_id=' + entity_id)
                .then((response) => {
                    this.entityInfos = response.data.data;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        downloadFile(url) {
            // Trigger file download
            window.open(this.api_url + url, '_blank');
        },
        updatePhoto(e) {
            this.bill.file = e.target.files[0];
            this.url = URL.createObjectURL(this.bill.file);
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            this.bill.file = file;
        },

        async getEntityContract() {
            this.load = true;
            await ApiService.get(
                'entity/contract/list?entity_info_id=' +
                this.bill.entity_id +
                '&tranche_id=' +
                this.bill.tranche_id
            )
                .then((response) => {
                    this.contractInfos = response.data.data;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },


        async formSubmit() {
            let user_id = VueCookieNext.getCookie('_seip_user');
            let user_role_id = VueCookieNext.getCookie('_seip_role_id');
            let formData = new FormData();
            formData.set('file', this.bill.file);
            formData.set('claim_no', this.bill.claim_no);
            formData.set('tranche_id', this.bill.tranche_id);
            formData.set('entity_id', this.bill.entity_id);
            formData.set('entity_contract_id', this.bill.entity_contract_id);
            formData.set('bill_sequence_no', this.bill.bill_sequence_no);
            formData.set('user_id', user_id.id);
            formData.set('user_role_id', user_role_id);
            this.loading = true;
            await ApiService.post('bill/archive-bill/save', formData)
                .then((response) => {
                    this.loading = false;
                     this.getList();
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'File Saved Succesfully',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => {
                            this.bill.file = '';
                        });
                    } else {

                        Swal.fire({
                            title: 'Something Wrong.Try Again',
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
                .catch(({ response }) => {
                    this.loading = false;
                    Swal.fire({
                        title: 'Unknown error',
                        html: response.data.error,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                    });
                    console.log(response);
                });
        },


        async getAllBillNo() {
            this.load = true;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            if (this.bill.claim_no == '1') {
                await ApiService.get(
                    'bill/claim_1/all_bill_no?entity_id=' +
                    this.bill.entity_id +
                    '&tranche_id=' +
                    this.bill.tranche_id +
                    '&entity_contract_id=' +
                    this.bill.entity_contract_id +
                    '&institute_info_id=' +
                    institute_info_id +
                    '&submitted_status=1'
                )
                    .then((response) => {
                        this.allBillNo = response.data.data;
                        this.load = false;
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            }
            if (this.bill.claim_no == '2') {
                await ApiService.get(
                    "bill/claim_2/all_bill_no?entity_id=" +
                    this.bill.entity_id +
                    "&tranche_id=" +
                    this.bill.tranche_id +
                    '&entity_contract_id=' +
                    this.bill.entity_contract_id +
                    '&institute_info_id=' +
                    institute_info_id
                )
                    .then((response) => {
                        this.allBillNo = response.data.data;
                        this.load = false;
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            }
            if (this.bill.claim_no == '3') {
                await ApiService.get(
                    "bill/claim_3/all_bill_no?entity_id=" +
                    this.bill.entity_id +
                    "&tranche_id=" +
                    this.bill.tranche_id +
                    "&entity_contract_id=" +
                    this.bill.entity_contract_id +
                    "&institute_info_id=" +
                    institute_info_id
                )
                    .then((response) => {
                        this.allBillNo = response.data.data;
                        this.load = false;
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            }
        },
        async getList() {
            this.showSection = true;
            this.loading = true;
            await ApiService.get(
                "bill/get_archive_bill?entity_id=" +
                this.bill.entity_id +
                "&tranche_id=" +
                this.bill.tranche_id +
                '&entity_contract_id=' +
                this.bill.entity_contract_id +
                '&bill_no=' + this.bill.bill_sequence_no +
                '&claim_no=' + this.bill.claim_no
            )
                .then((response) => {
                    this.allBillNo = response.data.data.data;
                    this.claimBillkey += 1;
                    this.load = false;
                    this.loading = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async submitBill() {
            let user_id = VueCookieNext.getCookie('_seip_user');
            user_id = user_id.id;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }

            let data = {
                tranche_id: this.bill.tranche_id,
                entity_id: this.bill.entity_id,
                bill_sequence_no: this.bill.bill_sequence_no,
                institute_info_id: institute_info_id,
                start_date: this.bill.bill_date,
                user_id: user_id,
            };

            this.loading = true;
            await ApiService.post('bill/claim_2/bill_submit', data)
                .then((response) => {
                    this.loading = false;
                    if (response.status == 200) {
                        this.billList = [];
                        this.batchList = [];
                        this.tolatTrainee = 0;
                        this.tolatAssessedTrainee = 0;
                        this.tolatCertifiedTrainee = 0;
                        this.tolatBillAmount = 0;
                        this.contributionPay = 0;
                        this.totalPayableAmount = 0;
                        this.totalContributionAmount = 0;
                        this.claimBillkey += 1;
                        this.showBillList = false;
                        Swal.fire({
                            title: 'Success!',
                            text: response.data.data,
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => { });
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: 'Please check all the required field',
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
                .catch(({ response }) => {
                    this.loading = false;
                    Swal.fire({
                        title: 'Unknown error',
                        html: response.data.error,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                    });
                    console.log(response);
                });
        },
        confirmSubmitBill() {
            Swal.fire({
                title: "You can't edit bill after submit. Are you sure to submit ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Submitted!',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitBill();
                }
            });
        },
        moment() {
            return moment();
        },
    },
    setup() { },
});
